<template>
    <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main ">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar" style="padding-top:0px !important;">
                <!-- content @s -->
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
                        <div class="brand-logo pb-4 text-center">
                            <a href="https://softsuite.co.uk" class="logo-link">
                               <img class="logo-light logo-img" src="@/assets/images/logos/logo-darkbg1.png"  alt="logo">
                               <img class="logo-dark logo-img" src="@/assets/images/logos/logo-lightbg1.png" alt="logo-dark">
                            </a>
                        </div>
                        <div class="card card-bordered">
                          <div v-if="requestSent"  class="card-inner card-inner-lg">
                             <div  class="pl-5 pr-5 pt-5 pb-5 bg-white text-center">
                              <h5>A password reset link has been sent to email address {{emailAddress}}</h5>
                              <p>You should receive this in the next few minutes.</p>
                              <div class="form-note-s2 text-center pt-4">
                                    <router-link :to="{name: 'Login'}"><strong>Return to login</strong></router-link>
                                </div>
                            </div>
                          </div>
                            <div v-if="!requestSent"  class="card-inner card-inner-lg">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h5 class="nk-block-title">Reset password</h5>
                                        <div class="nk-block-des">
                                            <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                                        </div>
                                    </div>
                                </div>
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="default-01">Email</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <input v-model="emailAddress" type="text" class="form-control form-control-lg" id="default-01" placeholder="Enter your email address">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button v-if="emailAddress" v-on:click="requestPasswordReset()" class="btn btn-lg btn-primary btn-block">Send Reset Link</button>
                                        <button v-if="!emailAddress" disabled  class="btn btn-lg btn-primary btn-block">Send Reset Link</button>
                                    </div> 
                                <div class="form-note-s2 text-center pt-4">
                                    <router-link :to="{name: 'Login'}"><strong>Return to login</strong></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="nk-footer nk-auth-footer-full">
                        <div class="container wide-lg">
                            <div class="row g-3">
                                <div class="col-lg-6 order-lg-last">
                                    <ul class="nav nav-sm justify-content-center justify-content-lg-end">
                                        <li class="nav-item">
                                            <a class="nav-link" href="https://softsuite.co.uk/termsandconditions">Terms & Condition</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="ttps://softsuite.co.uk/privacypolicy">Privacy Policy</a>
                                        </li> 
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="nk-block-content text-center text-lg-left">
                                        <p class="text-soft">&copy; 2021+ <a href="https://ravenware.co.uk">Ravenware Ltd</a> All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div>
</template>
<script>
export default {
  name: 'forgotpassword',
  methods: {
    requestPasswordReset () {
      this.$http.get('/Users/requestPasswordReset/' + this.emailAddress)
        .then(() => {
          this.requestSent = true
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    validEmail (email) {
      var re = /\S+@\S+\.\S+/
      return re.test(email)
    },
  },
  data: function () {
    return {
      requestSent: false,
      emailAddress: null, 
    }
  },
}
</script>
<style> 
</style>
